import { navigate } from 'gatsby-link';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Button from '../components/button/Button';
import { GreatThan } from '../components/images/svg';
import { StoreState } from '../modules';
import { Messages } from '../utils';
import { openNewPage } from '../utils/browser';

const DemoSample = process.env.GATSBY_IMAGE_PUBLIC_URL + "DemoSample.png"

interface Props {
  streamState: string
}

export default function ServiceSample({streamState}: Props):JSX.Element {
  const { video } = useSelector((state: StoreState) => ({video: state.streaming.video}));

  const goDemo = () => {
    if(video?.videoKey) {
      openNewPage(`/demo/live/detail/?id=${video.videoKey}`);
    } else {
      navigate('/demo');
    }
  }

  return (
    <div className="demo-sample-modal-container">
      <p className="landing-description montserrat-font" >{Messages.LIVE_DEMO_CONTENTS.description}</p>
      <div className="sample-content">
        <img src={DemoSample} alt="demo_sample_img" width="900" height="454.7"/>
        <div className="sample-desc-group">
          <div className="text-group">
            {Messages.LIVE_DEMO_CONTENTS.liveDescription.map((desc, i) => <p key={i}>{desc}</p>)}
          </div>
          <Button color="red" size="large" onClick={goDemo} disabled={streamState !== "Started"}>Go Demo<GreatThan /></Button>
        </div>
      </div>
    </div>
  )
}