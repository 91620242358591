import * as React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as streamingActions } from '../modules/streaming'
import Button from '../components/button/Button';
import { GreatThan } from '../components/images/svg';
import TextInput from '../components/input/TextInput';
import { Config, Messages } from '../utils';
import { MAX_LENGTH } from '../utils/Config';
import { GoodsInfo } from './live';
import StreamSubBox from './StreamSubBox';
import { StoreState } from '../modules';
import { isClient } from '../utils/browser';

interface Props {
  actions: typeof streamingActions
  title: string
  description: string
  goodsList: GoodsInfo[]
  toggleInfo(): void
}

interface State {
  title: string
  description: string
  buttonHover: boolean
}

class LiveSetting extends React.Component<Props, State> {
  state: State = {
    title: this.props.title,
    description: this.props.description,
    buttonHover: false,
  }

  onChangeTitle = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value, name } = e.currentTarget
    this.setState({ ...this.state, [name]: value })
    this.props.actions.setTitle(this.state.title);
  }

  onNext = () => {
    if(isClient && !localStorage.getItem(Config.DEMO_EMAIL_KEY)) {
      this.props.toggleInfo()
      return;
    }
    this.props.actions.setStreamState("Waiting")
  }

  onButtonOver = () => {
    this.setState({ buttonHover: true })
  }

  onButtonLeave = () => {
    this.setState({ buttonHover: false })
  }
  
  public render(): JSX.Element {
    return (
      <div className="live-content">
        <div className="card">
          <div className="card-header">Live setting</div>
          <form className="live-settings-modal-form">
            <TextInput type="text" id="title" name="title" value={this.state.title} onChange={this.onChangeTitle} label="Title" maxLength={MAX_LENGTH.videoTitle} />
            <StreamSubBox goodsList={this.props.goodsList} isCheckList />
          </form>
        </div>
        <div className="live-tool-group">
          <div className="input-group form-desc">
            {Messages.LIVE_DEMO_CONTENTS.liveDescription.map((message, i) => <div key={i} className="input-text"><small>{message}</small></div>)}
          </div>
          <div className="live-button-group">
            <Button size="medium" color="red-outline" onMouseLeave={this.onButtonLeave} onMouseOver={this.onButtonOver} onClick={this.onNext} style={{paddingLeft: "20px", minWidth: "120px", width: "120px"}}>Next<GreatThan className={!this.state.buttonHover ? "red-svg-icon" : ""}/></Button>
          </div>
        </div>
      </div>
    )
  }
}


export default connect(
  ({ streaming } : StoreState) => ({
    title: streaming.title,
    description: streaming.desc,
    goodsList: streaming.goodsList,
  }),
	(dispatch) => ({
    actions: bindActionCreators(streamingActions, dispatch),
  })
)(LiveSetting)
