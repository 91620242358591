import * as React from 'react'

interface Props {
  className?: string
}

export default function Stop({ className }: Props): JSX.Element {
  switch (className) {
    case 'white-svg-icon':
      return (
        <svg width={20} height={20} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter colorInterpolationFilters="auto" id="2136a">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g transform="translate(-1492 -963)" filter="url(#2136a)" fill="none" fillRule="evenodd">
            <path d="M1492 963h20v20h-20z" />
            <path d="M1507 967a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h10z" fill="#FFF" fillRule="nonzero" />
          </g>
        </svg>
      )
    case 'red-svg-icon':
      return (
        <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter colorInterpolationFilters="auto" id="prefiasdx__a2">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.996078 0 0 0 0 0.360784 0 0 0 0 0.000000 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g transform="translate(-1492 -895)" filter="url(#prefiasdx__a2)" fill="none" fillRule="evenodd">
            <path d="M1492 895h20v20h-20z" />
            <path d="M1507 899a1 1 0 011 1v10a1 1 0 01-1 1h-10a1 1 0 01-1-1v-10a1 1 0 011-1h10z" fill="#FFF" fillRule="nonzero" />
          </g>
        </svg>
      )
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
          <defs>
            <filter id="7px5gx5pia">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.996078 0 0 0 0 0.360784 0 0 0 0 0.000000 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g>
              <g filter="url(#7px5gx5pia)" transform="translate(-1401 -109) translate(1360 94)">
                <g>
                  <path d="M0 0H20V20H0z" transform="translate(41 15)" />
                  <path fill="#FFF" fillRule="nonzero" d="M15 4c.552 0 1 .448 1 1v10c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h10z" transform="translate(41 15)" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      )
  }
}
