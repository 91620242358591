import * as React from 'react';
import * as moment from 'moment';

import videojs from 'video.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { throttle } from 'lodash';
import { LiveBadge, LiveView, PlayWhite, Stop, VideoLike, VideoView } from '../components/images/svg';
import { actions as streamingActions } from '../modules/streaming'
import GoodsBubble from './GoodsBubble';
import ChatForm from './ChatForm';
import Button from '../components/button/Button';
import LiveViewer from './LiveViewer';
import Title from '../components/title/Title';
import { GoodsInfo, likeVideo, Message, MessageInfo, StreamVideoInfo, VideoInfo } from './live';
import Toast from '../components/Toast';
import { Config, Messages } from '../utils';
import { genUserName, secondsToTime } from '../utils/Format';
import { getAgent, isClient } from '../utils/browser';
import { StoreState } from '../modules';

interface Props {
  video: VideoInfo | null
  streamState: string
  actions: typeof streamingActions
  goodsList: GoodsInfo[]
  title: string
  toggleInfo(): void
}

interface State {
  video: StreamVideoInfo | null
  messages: MessageInfo[]
  muted: boolean
  likeCnt: number
  duration: string
  videoLike: number
  videoView: number
  toggleTool: boolean
  visibleModal: number
  buttonHover: boolean
}

class LiveStream extends React.Component<Props, State> {
  state: State = {
    video: null,
    messages: [],
    muted: true,
    likeCnt: 1,
    duration: '00:00',
    videoLike: 0,
    videoView: 1,
    toggleTool: false,
    visibleModal: 0,
    buttonHover: false
  }

  videoNode: any
  viewer: videojs.Player;;
  gossip: any
  streamer: any
  sdk: any

  
  initDemo = async () => {
    const { FlipFlop } = (await import('flipflop-sdk-javascript/dist/flipflop'))
    const appKey = Config.DEMO_APP_KEY
    const appSecret = Config.DEMO_APP_SECRET
    let username = isClient && localStorage.getItem(Config.DEMO_USERNAME_KEY)
    const profilePhoto = ""
    

    if(!username) {
      username = genUserName()
      localStorage.setItem(Config.DEMO_USERNAME_KEY, username)
    }

    console.log(`userId: ${username}, userName: ${username}`);
    
    FlipFlop.initialize(appKey, appSecret);
    this.sdk = await FlipFlop.authentication(username, username, profilePhoto);

    this.streamer = this.sdk.getStreamer()
    this.streamer.delegate = this

    this.setVideo({ 
      title: this.props.title, 
      content: "", 
      user_id: username, 
      user_name: username,
      user_avatar_url: profilePhoto
     })

    this.props.actions.setSdk(this.sdk)
  }

  setVideo = (video: StreamVideoInfo) => {
    this.setState({video})

    if (this.streamer) {
      this.streamer.prepare(this.videoNode)
    }
  }


  start = async () => {
    if(isClient && !localStorage.getItem(Config.DEMO_EMAIL_KEY)) {
      this.props.toggleInfo()
      return;
    }
    
    const { goodsList } = this.props

    if (this.streamer) {
      const video = await this.streamer.start(
        this.props.title,
        "",
        JSON.stringify({goods_list: goodsList}),
        getAgent()
      )

      this.props.actions.setVideo(new VideoInfo(video))
      console.log("video section", new VideoInfo(video))
      localStorage.removeItem(Config.DEMO_EMAIL_KEY);
    } 
  }

  stop = (force?: boolean): void => {
    if (!force && !window.confirm('Do you want to stop LIVE?')) {
      return
    }
    
    try {
      if (this.streamer) {
        this.streamer.stop()
        this.streamer.reset()
        this.streamer = null
      }
    } catch(e) {
      console.log(e);      
    }
  }

  setStreamState = (streamState: string) => {
    console.log(`--- janus state: ${streamState}`)
    this.props.actions.setStreamState(streamState)
  }

  onPrepare = () => {
    console.log('onPrepare')
    this.setStreamState('Ready')
  }

  onStart = (streamName: string) => {
    console.log(`onStart: ${streamName}`)
    this.setStreamState('Started')
  }

  onStop = () => {
    console.log(`onStopped`)
    this.setStreamState('Stopped')
  }

  onCompleted = () => {
    console.log(`onCompleted`)
  }

  onError = (error: string) => {
    if (error){
      console.error(error)
    }
  }

  onChatReceived = (message: Message) => {
    message.elapsedTime = Date.now() - message.created_at

    const { messages } = this.state;
    console.log("message.message_type", message.message_type)

    if(message.message_type === "JOIN" || message.message_type === "LEAVE") {
      message?.total_user_count && this.setState({videoView: message?.total_user_count});
      return;
    }

    if(message.custom_type === "UPDATE") {
      const data = JSON.parse(message?.data || "");
      if(data?.like_count) {
        this.setState({videoLike: data.like_count});
      }
      return;
    }
    this.setState({ messages: messages.concat(new MessageInfo(message))});
  }

  sendMessage = (message: string): void => {
    console.log("this.streamer", this.streamer)
    if (this.streamer) {
      this.streamer.sendMessage(message, "MANAGER")
    }
  }

  onHeart = () => {
    const { likeCnt } = this.state
    const { video } = this.props

    if(video instanceof VideoInfo) {
      this.setState(prev => ({likeCnt: prev.likeCnt + 1}))
      console.log(this.state.likeCnt)

      likeVideo(video?.videoKey, likeCnt, () => {
        this.setState({likeCnt: 1})
      })
    }
  }

  onTimeUpdate = throttle((): void => {
    if(this.props.video instanceof VideoInfo) {
      const seconds = moment().unix() - moment(this.props.video.createdAt).unix()
      if(seconds > (60 * 5)) {
        this.stop(true);
        return;
      }
      const diffTime = secondsToTime(seconds)
      
      this.setState({ duration: diffTime });
    }
  }, 1000)

  onButtonOver = () => {
    this.setState({ buttonHover: true })
  }

  onButtonLeave = () => {
    this.setState({ buttonHover: false })
  }

  onCopyUrl = () => {
    navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/demo/live/viewer/?id=${this.props.video?.videoKey}`);
    Toast.notify('The URL to share has been copied.');
  }

  componentDidMount = () => {
    this.initDemo();
  }

  // shouldComponentUpdate(nextProps: Props): boolean {
  //   console.log("nextProps.streamState", nextProps.streamState)
  //   if (nextProps.streamState == "Waiting") {
  //     return true
  //   }

  //   return true
  // }

  componentWillUnmount() {
    if (this.gossip) {
      this.gossip.disconnect()
    }

    if (this.streamer) {
      this.streamer.stop()
      this.streamer.reset()
      this.props.actions.setPlayer(null);
    }

    this.props.actions.reset();
  }

  render(): JSX.Element | null {
    const { video, streamState } = this.props;

    return (
      <div className="live-content" style={(streamState !== "Ready" && streamState !== "initialized") ? {display: "block"} : {display: "none"}}>
        <div className="live-title-group">
          <Title color="black" size="small" className="montserrat-font">Live Manager</Title>
          <Title color="black" size="small" className="montserrat-font">Live Viewer</Title>
        </div>
        <div className="demo-viewer-container">
          <div id="video-wrap" className="video-viewer-content video-streaming-group">
            {!this.state.toggleTool && <div className="video-header">
              <div className="video-info">
                <div className="video-title-group">
                  <p className="title">{video?.title || this.props.title || 'No title'}</p>
                  <div className="sub-title">
                    <div>
                      <p className="user-name text-ellipsis">{video?.user?.username || this.state.video?.user_name || '...'}</p>
                      {video?.state === "CREATED" && <LiveBadge />}
                      <div className="icon-group">
                        <div className="icon-item"><VideoView className="white-svg-icon" /><span>&nbsp;&nbsp;{this.state.videoView}&nbsp;&nbsp;</span>•&nbsp;</div>
                        <div className="icon-item"><VideoLike className="white-svg-icon" /><span>&nbsp;&nbsp;{this.state.videoLike}</span></div>
                      </div>
                    </div>
                    <div className="video-info-overlay">
                      <div className="duration-overlay">
                        {this.state.duration}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="btn-group">
                <button type="button" onClick={this.onMute}>
                  { this.viewer?.muted() ? <Muted /> : <Unmuted /> }
                </button>
                <button type="button" onClick={this.onClose}>
                  <CloseWhite/>
                </button>
              </div> */}
              {this.props.goodsList.length > 0 && <GoodsBubble style={{top: "140px", left: "10px"}} goodsList={this.props.goodsList}/>}
            </div>}
            <div className="video-group">
              <video ref={node => this.videoNode = node} autoPlay={true} playsInline onTimeUpdate={this.onTimeUpdate} height="191" onClick={() => {this.setState(prev => ({toggleTool: !prev.toggleTool}))}}/>
              <div className="gossip-group">
                <ChatForm messages={this.state.messages} onHeart={this.onHeart} sendMessage={this.sendMessage}/>
              </div>
            </div>
          </div>
          <LiveViewer />
        </div>
        <div className="live-tool-group">
          <div className="input-group form-desc">
            {Messages.LIVE_DEMO_CONTENTS.liveDescription.map((message, i) => <div key={i} className="input-text"><small>{message}</small></div>)}
          </div>
          <div className="live-button-group">
          {streamState === 'Waiting' ?
            <Button size="medium" color="red" onClick={this.start} ><PlayWhite />Start Live</Button> :
              streamState === 'Started' ?
              <div className="live-button-group">
                {this.props.video?.videoKey && <Button size="medium" color="white-blue" onClick={this.onCopyUrl} ><LiveView /><span>Live Share</span></Button>}
                <Button size="medium" color="upload" onMouseLeave={this.onButtonLeave} onMouseOver={this.onButtonOver} onClick={this.stop} ><Stop className={this.state.buttonHover ? "white-svg-icon" : "red-svg-icon"} /><span>Stop Live</span></Button>
              </div> :
              streamState === 'Stopped' && ''}
          </div>
        </div>
      </div>
    )
  }
}


export default connect(
	({ streaming } : StoreState) => ({
    goodsList: streaming.goodsList,
    title: streaming.title,
    video: streaming.video,
    streamState: streaming.streamState
	}),
	(dispatch) => ({
    actions: bindActionCreators(streamingActions, dispatch),
  })
)(LiveStream)
