import * as React from 'react';

interface Props {
  className?: string
}

export default function GreatThan({ className }: Props): JSX.Element {
  switch (className) {
    case 'red-svg-icon':
      return (
        <svg width={17} height={17} viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter colorInterpolationFilters="auto" id="a123">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.996078 0 0 0 0 0.360784 0 0 0 0 0.000000 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g transform="translate(-1374 -897)" filter="url(#a123)" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <path d="M1374 897h17v17h-17z" />
              <path d="m1380.5 910.45-.707-.707 4.243-4.243-4.243-4.243.707-.707 4.95 4.95-4.95 4.95z" fill="#000" />
            </g>
          </g>
        </svg>
      )
    default:
      return (
        <svg width={17} height={17} xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter colorInterpolationFilters="auto" id="12342">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0" />
            </filter>
          </defs>
          <g transform="translate(-103 -2)" filter="url(#12342)" fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <path d="M103 2h17v17h-17z" />
              <path d="M109.5 15.45l-.707-.707 4.243-4.243-4.243-4.243.707-.707 4.95 4.95-4.95 4.95z" fill="#000" className={className} />
            </g>
          </g>
        </svg>
      )
  }
}
