import { Link, navigate } from 'gatsby';
import * as React from 'react';
import { useState } from 'react'
import Button from '../components/button/Button'
import TextInput from '../components/input/TextInput'
import { Config } from '../utils';

interface Props {
  toggle(): void
}

export default function InformationSample({toggle}: Props): JSX.Element {
  const [company, setCompany] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [companyError, setCompanyError] = useState('')
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [confirmPolicy, setConfirmPolicy] = useState(false)

  const onChangeCheck = (e: React.FormEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.checked
    setConfirmPolicy(value)
  }

  const onChangeInput = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget
    switch (name) {
      case 'company':
        setCompany(value)
        break
      case 'name':
        setName(value)
        break
      default:
        setEmail(value)
        return
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (company === '') {
      setCompanyError('Please enter your company')
      return
    }
    setCompanyError('')

    if (name === '') {
      setNameError('Please enter your name')
      return
    }
    setNameError('')

    if (email === '') {
      setEmailError('Please enter your email')
      return
    }
    setEmailError('')

    const request = {
      company,
      name,
      email
    }

    localStorage.setItem(Config.DEMO_EMAIL_KEY, email);
    toggle();
  }

  return (
    <form className="demo-information-modal-container" onSubmit={handleSubmit}>
      <div className="input-double-group">
        <TextInput type="text" name="company" label="Company" value={company} onChange={onChangeInput} maxLength={200} required error={companyError} />
        <TextInput type="text" name="name" label="Name" value={name} onChange={onChangeInput} maxLength={200} required error={nameError} />
      </div>
      <TextInput type="email" name="email" label="Email" value={email} onChange={onChangeInput} maxLength={200} required error={emailError} />
      <div className="form-policy-group">
        <div className="form-check">
          <input className="form-check-input" type="checkbox" id="confirmPolicy" checked={confirmPolicy} onChange={onChangeCheck} />
          <label className="form-check-label" htmlFor="confirmPolicy"></label>
        </div>
        <div className="form-check-message">
          <p>By clicking the submit button, you agree to allow FLIPFLOP to process the information above for contact purposes. Please read our <a href="https://dashboard.flipflop.tv/privacy_policy">Privacy Policy.</a></p>
        </div>
      </div>
      <div className="button-double-group">
        <Button type="button" size="large" color="white" onClick={() => {navigate("/demo")}}>Cancel</Button>
        <Button type="submit" size="large" color="red" disabled={!confirmPolicy}>Submit</Button>
      </div>
    </form>
  )
}
