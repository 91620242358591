import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as streamingActions } from '../../../modules/streaming'
import Footer from '../../../components/Footer';
import Title from '../../../components/title/Title';
import Button from '../../../components/button/Button';
import Modal from '../../../components/modal/Modal';
import LayoutProvider from '../../../components/layout/LayoutProvider';
import { Config, Messages } from '../../../utils';
import { GreatThan } from '../../../components/images/svg';
import LiveStreaming from '../../../live/LiveStreaming';
import LiveSetting from '../../../live/LiveSetting';
import InformationSample from '../../../demo/InfomationSample';
import ServiceSample from '../../../live/ServiceSample';
import { GoodsInfo, StreamVideoInfo, VideoInfo } from '../../../live/live';
import { StoreState } from '../../../modules';
import { isClient } from '../../../utils/browser';
import { navigate } from 'gatsby-link';

enum ModalStateCode {
  Sample = 1,
  Info = 2,
}

interface Props {
  actions: typeof streamingActions
  goodsList: GoodsInfo[]
  streamState: string
  title: string
  video: VideoInfo | StreamVideoInfo | null
  location: any
}

interface State {
  stage: number
  username: string
  visibleModal: ModalStateCode
  visibleInfo: boolean
}

class DemoContainer extends React.Component<Props, State> {
  state: State = {
    stage: 0,
    username: '',
    visibleModal: 0,
    visibleInfo: !Boolean(isClient && localStorage.getItem(Config.DEMO_EMAIL_KEY))
  }


  toggleModalGenerator = (modalCode: ModalStateCode) => () => {
    if(this.state.visibleModal === modalCode) {
      this.setState({visibleModal: 0})
    } else {
      this.setState({visibleModal: modalCode})
    }
  }

  toggleService = this.toggleModalGenerator(ModalStateCode.Sample)
  
  toggleInfo = () => {
    this.setState(prevState => ({visibleInfo: !prevState.visibleInfo}))
  }

  public render() {
    const {title, goodsList, streamState} = this.props

    console.log("streamState", title, goodsList, streamState)

    return (
      <LayoutProvider location={this.props.location} className="demo-container" render={() => 
        <>
          <div className="content-container">
            <div className="demo-content row">
              <Title color="black" size="xlarge" className="montserrat-font" >
                Live stream
              </Title>
              <p className="landing-description montserrat-font" >{Messages.LIVE_DEMO_CONTENTS.description}</p>
              <Button color="orange" size="medium" onClick={this.toggleService} style={{paddingLeft: "15px"}}>Service Sample <GreatThan /></Button>
              {streamState === 'Ready' && <LiveSetting toggleInfo={this.toggleInfo}/>}
              <LiveStreaming toggleInfo={this.toggleInfo} />
            </div>
          </div>
          <Footer />
          <Modal className="demo-modal" visible={this.state.visibleModal === ModalStateCode.Sample} setVisible={this.toggleService} render={<ServiceSample streamState={streamState} />} title={"Service Sample"} />
          <Modal visible={this.state.visibleInfo} setVisible={this.toggleInfo} onClose={() =>{navigate("/demo")}} render={<InformationSample toggle={this.toggleInfo}/>} title={"Infomation"} disabled />
        </>
      } />
    )
  }
}

export default connect(
	({ streaming } : StoreState) => ({
    goodsList: streaming.goodsList,
    title: streaming.title,
    video: streaming.video,
    streamState: streaming.streamState,
	}),
	(dispatch) => ({
    actions: bindActionCreators(streamingActions, dispatch),
  })
)(DemoContainer)